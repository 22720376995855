.navigation {
  background: #242424 0% 0% no-repeat padding-box;
  border-radius: 0px 20px 20px 0px;
  opacity: 1;
  width: 40px;
  position: fixed;
  top: 40%;
  color: white;
  z-index: 100;
}

.sideNav {
  padding-bottom: 23px;
}

.sideNav ul {
  list-style: none;
}

.sideNav li {
  list-style: none;
  display: flex;

  &:hover {
    img {
      filter: brightness(0) invert(1);
    }

    a.sideNav-link {
      filter: brightness(0) invert(1);
    }
  }
}

a.sideNav-link {
  color: white;
  font-size: medium;
  text-decoration: none;
  width: 100%;
}

.sideNav img {
  @extend img;
  margin-top: 29px;
  margin-left: 10px;
  width: 17px;
  height: 21px;
  opacity: 1;
  float: left;
}

.sideNav--expanded {
  background: #242424 0% 0% no-repeat padding-box;
  border-radius: 0px 20px 20px 0px;
  opacity: 1;
  width: 150px;
  color: white;
}

.sideNav-link p {
  display: inline-block;
  margin-top: 28px;
  margin-left: 19px;
  height: 5px;
  color: gray;
}

.nav-border {
  height: 30px;
  margin-top: 22px;
  border-left: 5px solid orange;
}

.icon--active {
  filter: brightness(0) invert(1);
}

.link--active {
  filter: brightness(0) invert(1);
  color: white;
}
