.hue-horizontal {
  margin-bottom: 10px;
  padding: 0px 2px;
  position: relative;
  margin-top: 37px;
  width: 201px;
  margin-left: 11px;
}

.compact-picker {
  padding-top: 5px;
  padding-left: 13px !important;
  box-sizing: initial;
  width: 211px !important;
  background-color: black;
  height: -1px;
  .div {
    margin-right: 10px;
  }
}

.flexbox-fix {
  display: none !important;
}
