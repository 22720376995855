.MuiCalendarPicker-root,
.MuiDateCalendar-root {
  background-color: #121212 !important;
  color: #fff !important;
}
.MuiPickersDay-root {
  color: #fff !important;
}

.MuiPickersDay-root:hover {
  background-color: #242424 !important;
  border-radius: 8px;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #ffa901 !important;
  border-radius: 8px;
}

.PrivatePickersFadeTransitionGroup-root {
  color: #fff;
}

.MuiPickersDay-today {
  border: 1px solid #807e7e !important;
  border-radius: 8px !important;
}

.productsData {
  background-color: #242424 !important;
  border-radius: 8px !important;
}

.PrivatePickersYear-yearButton.Mui-selected.css-m1gykc {
  background-color: #ffa901 !important;
}

.MuiCalendarPicker-root button {
  color: #fff !important;
}

.MuiCalendarPicker-root .MuiInputAdornment-root .MuiSvgIcon-fontSizeMedium {
  color: #0000008a;
}

.MuiDayCalendar-weekDayLabel {
  color: #9d9d9d !important;
}

.MuiCalendarPicker-root .MuiIconButton-root:hover {
  background-color: #6f6f6f;
}
.MuiCalendarPicker-root,
.MuiDateCalendar-root {
  .MuiSvgIcon-root {
    fill: white;
  }
}
.react-datepicker__header {
  background-color: #000000;
  color: #fff !important;
  border-bottom: none;
}

.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker__month,
.react-datepicker__day {
  color: #fff !important;
}

.react-datepicker__current-month {
  font-weight: 500;
}

.react-datepicker__input-container {
  font-size: 16px;
}

.react-datepicker__input-container > input,
.react-datepicker-ignore-onclickoutside {
  border: none;
  font-size: 16px;
  text-align: center;
}

.react-datepicker__view-calendar-icon input {
  padding: 7px 2px 3px 7px;
  width: 140px;
}

.react-datepicker__month-dropdown-container--select {
  margin-top: 10px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  color: white;
  background-color: #3d3d3d;
  border-radius: 5px;
  height: 29px;
  border: none;
  padding: 0px 5px;
}

.react-datepicker__day-names {
  font-weight: 750;
}

.react-datepicker {
  background-color: #000000;
  color: #fff !important;
  &__day {
    font-size: 13px !important;
    &:hover {
      background-color: #3d3d3d;
    }
    &--selected {
      border-radius: 25px !important;
      background: #ffa901 !important;
      color: white !important;
      border-radius: 8px !important;
    }
    &--outside-month {
      color: rgb(193, 186, 186) !important;
    }
    &--disabled {
      color: #c7c7c7 !important;
    }
  }
}
