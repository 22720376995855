.logo__image {
  margin-top: 18px;
  width: 158px;
  height: 36px;
  margin-left: 17px;
  float: left;
}

.logo__route {
  margin-left: 20px;
  margin-top: 25px;
  float: left;
  color: black;
  font-family: 'Roboto';
  border-left: 2px solid black;
  height: 30px;
  font-size: 22px;
}

.logo__routeLabel {
  margin-left: 20px;
  font-size: 22px;
  margin-top: 26px;
  color: black;
  float: left;
}
