$defaultBackgroundColor: #3f3f3f;
$selectedBackgroundColor: #000000;

.iconButton {
  width: 74px;
  height: 44px;
  margin-top: 9px !important;
  background-color: $defaultBackgroundColor !important;
}

.selectedButton {
  background-color: $selectedBackgroundColor !important;
}

.selectedIcon {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(1deg);
}
.disabledIcon {
  filter: brightness(0.45);
}

.icon {
  width: fit-content;
  height: fit-content;
}
