.menu__userName {
  display: inline;
  float: right;
  // margin-right: 46px;
  color: black;
  font-size: 20px;
  // margin-top: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: '100px';
}

.menu__iconStyle {
  // margin-top: 18px !important;
   //float: left;
 // margin-right: -90px !important;
}

.menu__icon--unselected {
  color: black;
}

.menu__icon--selected {
  color: orange;
}

.menu__iconFormat {
  margin-right: 11px;
}
