.daterangepicker .ranges li.active {
  background-color: #ffa500;
  color: #fff;
}

/* Start Date */
.daterangepicker td.start-date,
.daterangepicker td.start-date.active {
  background-color: #ffa500;
  border-radius: 8px;
}

/* End Date */
.daterangepicker td.end-date,
.daterangepicker td.end-date.active {
  background-color: #ffa500;
  border-radius: 8px;
  opacity: 1;
}

/* Today (Initial Color) */
.daterangepicker td.today.active {
  background-color: #ffa500;
  border-radius: 8px;
}
.daterangepicker td.in-range {
  margin: 0.25em 0;
  opacity: 0.8;
  transition: 450ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 8px;
  transform: scale(1);
  background-color: unset;
}

.applyBtn {
  position: relative;
  overflow: hidden;
  border-width: 0;
  outline: 0;
  padding: 0 6px;
  cursor: pointer;
  border-radius: 9px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 60%);
  background: #ffa901 0% 0% no-repeat padding-box;
  color: #242424;
  transition: background-color 0.4s;
  height: auto;
  text-transform: uppercase;
  line-height: 36px;
  border: none;
  font-size: 16px;
  width: 100px;
  height: 38px;
}

.cancelBtn {
  position: relative;
  overflow: hidden;
  border-width: 0;
  outline: 0;
  padding: 0 6px;
  cursor: pointer;
  border-radius: 9px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 60%);
  background-color: #3d3d3d;
  color: #ecf0f1;
  transition: background-color 0.4s;
  height: auto;
  text-transform: uppercase;
  line-height: 36px;
  border: none;
  font-size: 16px;
  width: 100px;
  height: 38px;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #ffa500;
  border-color: #ffa500;
}

.btn-primary:disabled {
  background-color: #3d3d3d;
  border-color: #3d3d3d;
}

.daterangepicker .drp-selected {
  color: #ecf0f1;
}

.daterangepicker .calendar-table {
  border-radius: 4px;
  background: #121212 0% 0% no-repeat padding-box;
  border: 1px solid #212529;
}

.daterangepicker {
  background: #121212 0% 0% no-repeat padding-box;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #212529;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  color: #fff;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  border-color: transparent;
  color: #3d3d3d !important;
  background-color: transparent;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border: solid #f8f9fa;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #242424;
  color: #fff;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
  color: #fff;
  transition: 450ms cubic-bezier(0.23, 1, 0.32, 1);
}

.daterangepicker .ranges li:hover {
  background-color: #242424;
}

.daterangepicker .drp-buttons {
  border-top: 0px;
}

.daterangepicker {
  border: solid #000;
}

.daterangepicker:before,
.daterangepicker:after {
  display: none;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  color: white;
  background-color: #3d3d3d;
  border-radius: 5px;
  height: 29px;
  border: none;
  padding: 0px 5px;
}
