.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: default;
}
.mapboxgl-canvas1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0%;
  width: 50%;
  height: 91%;
  z-index: 0;
  cursor: default;
}
.mapboxgl-canvas2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 91%;
  z-index: 0;
  cursor: default;
  margin-left: 50%;
}

.mapbox-logo {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-scale {
  margin-bottom: 5px !important;
  margin-right: 240px !important;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid black;
  font-weight: 800;
  font-size: 13px;
  background-color: unset;
  text-align: center;
}

.mapboxgl-ctrl-scale::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 3px;
  height: 30%;
  bottom: 0;
  right: 0px;
}

.mapboxgl-ctrl-scale::before {
  content: '';
  position: absolute;
  background-color: black;
  width: 3px;
  height: 30%;
  bottom: 0;
  left: 0px;
}

#dummy-cursor-right {
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 70;
}

#dummy-cursor-left {
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 70;
}

.crosshair {
  position: relative;
}

.crosshair:before,
.crosshair:after {
  content: '+';
  display: inline-block;
  text-align: center;
  position: absolute;
  font-size: 35px;
  color: white;
  font-weight: 0;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
