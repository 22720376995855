.dashboardMapCanvas {
  .mapboxgl-canvas {
    position: relative;
    z-index: 0;
  }
}

.mapbox-logo {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
