.backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url('./../../assets/images/login_background.png') 0% 0% no-repeat
    padding-box;
  align-items: center;
  display: flex;
  background-size: cover;
  overflow: hidden;
}
