.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #6f6f6f;

  .action-msg-wrapper {
    display: flex;

    p {
      margin-top: 6px;
      margin-left: -4px;
    }
  }

  h4 {
    color: #979696;
  }

  svg {
    font-size: 350px;
  }
}
