.switch {
  margin: 18px !important;
}

.switchWrapper {
  background-color: #242424;
  height: 80px;
  color: white;
  margin-top: -8px;
}

.MuiChip-deleteIcon {
  display: none;
  background-color: orange;
  border-radius: 50%;
  color: black;
}

.MuiInputLabel-root {
  color: white !important;
}

.MuiChip-deleteIcon:hover {
  display: block !important;
  background-color: orange;
  border-radius: 50%;
}

.select {
  width: 250px;
}

.MuiAutocomplete-input,
.MuiAutocomplete-noOptions {
  color: #fff !important;
}

.autoComplete {
  background: #242424 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  opacity: 1;
  margin-top: 30px;
  margin-left: 15px;
  width: 300px;
}

.MuiOutlinedInput-notchedOutline {
  display: none;
  color: white;
}

.autoComplete .MuiOutlinedInput-root {
  border-radius: 9px;
  height: 40px;
  color: white;
}

.chip {
  margin-left: 2px;
  height: 27px !important;
  color: white !important;
  background: #000000 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.chipWrapper {
  overflow-y: scroll;
  border-radius: 4px;
  margin-left: 15px;
  padding: 5px;
  background-color: #242424;
  max-height: 75px;
}

.muichip-deleteicon {
  display: 'none';
}

:hover {
  .muichip-deleteicon {
    display: 'block';
    transition: display 10s;
  }
}

.Mui-checked {
  color: orange !important;
}

.checkboxIcon {
  color: white;
}

.listIcon {
  padding-left: 10px;
  height: 25px;
  width: 25px;
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.autoComplete .MuiInputLabel-root {
  top: -7px;
}

.MuiAutocomplete-popupIndicator {
  margin-top: 8px !important;
}
